<template>
    <div class="auto-start-conversation-modal">
        <div>
            <a-spin size="large"></a-spin>
            <h1>Starting Conversation...</h1>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import organisationHelpers from '../../../../../helpers/organisations';
import organisations from '../../../../../api/organisations';
import chats from '../../../../../api/chats';
import { mapGetters, mapActions } from 'vuex';

export default {
    props: ['presenceId', 'orgId'],
    data() {
        return {
            presences: [],
            isLoadingPresences: false
        };
    },
    created() {
        this.fetchPresencesAndStartConversation();
    },
    computed: {
        ...mapGetters('missionControl', {
            organisations: 'organisations',
            myPresences: 'presences'
        }),

        ...mapGetters('chats', {
            chats: 'chats'
        }),

        myPresenceId() {
            let presence = _.find(this.myPresences, {
                ownerId: this.orgId
            });

            return presence ? presence.id : null;
        }
    },
    methods: {
        ...mapActions('chats', {
            pushChat: 'pushChat'
        }),

        getMatchingChats(proposedMembers) {
            let proposedMembersSorted = _.sortBy(proposedMembers);
            let proposedMembersSortedJson = JSON.stringify(proposedMembersSorted);

            // Other signatures
            return _.filter(this.chats, (chat) => {
                let membership = chat.membership ? chat.membership : [];
                if (membership.length) {
                    let membershipIdsSorted = _.sortBy(_.map(membership, "id"));
                    return proposedMembersSortedJson === JSON.stringify(membershipIdsSorted);
                }
                return false;
            });
        },

        getOrganisationById(id) {
            return _.find(this.organisationsWithChatEnabled, { id });
        },

        navigateToChat(chat) {
            let organisation = _.find(this.organisations, { id: this.orgId });
            let tenantId = organisationHelpers.getOrganisationTenantId(organisation);
            this.$router.push("/chats/" + tenantId + "/" + chat.id);
        },

        startConversation() {
            let presenceIds = [];

            _.each(this.presences, (presence) => {
                if (presence.isResponder) {
                    presenceIds.push(presence.id);
                }
            });

            // Add my presenceId to the end of the array
            presenceIds.push(this.myPresenceId);

            // Add presenceId to the end of the array
            presenceIds.push(this.presenceId);

            // Make unique
            presenceIds = _.uniq(presenceIds);

            // Check if chat already exists
            let matchingChats = this.getMatchingChats(presenceIds);

            if (matchingChats.length) {
                this.navigateToChat(matchingChats[0]);
                return;
            } else {
                this.createChat(presenceIds);
            }
        },

        createChat(presenceIds) {
            let organisation = _.find(this.organisations, { id: this.orgId });
            let tenantId = organisationHelpers.getOrganisationTenantId(organisation);

            let chatRequest = {
                AddMembers: presenceIds,
                CreatedBy: this.myPresenceId,
                DisplayName: null,
                HasCustomAvatar: false,
                OwnerId: this.orgId,
            };

            chats
                .createChat(tenantId, chatRequest)
                .then((r) => {
                    this.$message.success("Chat created successfully");
                    this.pushChat(r.data);
                    this.navigateToChat(r.data);
                });
        },

        fetchPresencesAndStartConversation() {
            let organisation = _.find(this.organisations, { id: this.orgId });
            let tenantId = organisationHelpers.getOrganisationTenantId(organisation);

            let vm = this;

            this.isLoadingPresences = true;
            organisations.getMembershipForOrg(tenantId, this.orgId).then((r) => {
                vm.presences = r.data;
                vm.isLoadingPresences = false;
                vm.startConversation();
            }).catch(e => {
                console.log(e);
                this.isLoadingPresences = false;
                this.$message.error('Error loading presences');
                this.$emit('close');
            });
        }
    }
};
</script>

<style scoped lang="scss">
.auto-start-conversation-modal {
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 900000;
    display: flex;

    align-items: center;
    justify-items: center;
    justify-content: center;

    div {
        text-align: center;

        h1 {
            color: #fff;
            margin-top: 20px;
            font-weight: 300;
            font-size: 27px;
        }
    }
}
</style>