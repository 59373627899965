<template>
  <a-modal
    class="fullscreen-modal view-guidance-message-modal"
    centered
    v-model="showModal"
    title="Guidance"
    :footer="null"
  >
    <div class="outer-wrapper">
      <div class="inner-wrapper">
        <div class="loader" v-if="isLoadingCombined">
          <a-spin></a-spin>
        </div>

        <!-- Selected guidance pack -->
        <guidance-pack
          v-if="!isLoadingCombined && visible"
          :hide-back-button="true"
          :tenant-id="tenantId"
          @go-back="() => $emit('close-modal')"
          :guidance-pack-id="guidancePackId"
          :initial-content-id="contentId"
        ></guidance-pack>
        <!-- / Selected guidance pack -->
      </div>
    </div>
  </a-modal>
</template>

<script>
import GuidancePack from "./SendMessageActions/SendGuidanceModal/GuidancePack.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["visible", "myPresenceId", "guidancePackId", "contentId"],
  components: { GuidancePack },
  data() {
    return {};
  },
  watch: {
    showModal(isVisible) {
      if (isVisible) {
        this.reset();
        // this.loadGuidancePacks();
      }
    },
  },
  computed: {
    ...mapGetters("missionControl", {
      isLoadingMissionControl: "isLoading",
    }),

    ...mapGetters("timeline", {
      tenantId: "tenantId",
    }),

    ...mapGetters("guidance", {
      guidancePacks: "guidancePacks",
      isLoading: "isLoading",
    }),

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    },

    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close-modal");
        }
      },
    },
  },
  methods: {
    ...mapActions("guidance", {
      loadGuidancePacks: "loadGuidancePacks",
    }),

    ...mapActions("guidancePackViewer", {
      reset: "reset",
    }),

    selectGuidancePack(guidancePack) {
      this.selectedGuidancePack = guidancePack;
    },
  },
};
</script>

<style lang="scss">
.view-guidance-message-modal {
  .outer-wrapper {
    background: rgb(247, 248, 250);
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
    height: 100%;
    overflow-y: hidden;

    .inner-wrapper {
      padding-left: 10px;
      padding-right: 10px;
      height: 100%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }

  .guidance-pack-card-wrapper {
    margin-bottom: 20px;
  }
}
</style>