<template>
<div class="member-badge" :class="{'hidden': shouldHide}">
    <a-spin size="small" v-if="isLoading" />
  <!-- <a-badge
    :number-style="{
      color: 'rgba(0, 0, 0, 0.65)',
      background: '#f5c2cb',
    }"
    :count="presence.displayName"
  ></a-badge> -->
    <presence-badge v-if="!isLoading"
     :presence-id="presence.id" :owner-id="presence.ownerId"
             :name="presence.displayName"></presence-badge>
</div>
</template>

<script>
import presences from "../../../../api/presences";
import PresenceBadge from "../../../../components/Presences/PresenceBadge.vue";
export default {
  props: ["member", "tenantId", "announcementId", "isInline"],
  components: {PresenceBadge},
  data() {
      return {
          isLoading: false,
          presence: false
      }
  },
  created() {
      this.loadMemberDetails();
  },
  computed: {
    shouldHide() {
      if (this.isLoading) {
        return false;
      }
      return !this.presence;
    }
  },
  methods: {
      loadMemberDetails() {
          let vm = this;
          vm.isLoading = true;
          presences.getPresence(this.tenantId, this.member.presenceId).then(r => {
              vm.isLoading = false;
              vm.presence = r.data;
          }).catch(e => {
              console.log(e);
              vm.isLoading = false;
            //   vm.$message.error('Error loading member details');
          });
      }
  }
};
</script>

<style scoped lang="scss">
.member-badge {
    display: inline-block;

    &.hidden {
      display: none !important;
    }
}
</style>