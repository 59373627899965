<template>
  <div class="presence-row">
    <auto-start-conversation-modal @close="closeConversationModal"
     :presence-id="member.presenceId" :org-id="orgId" v-if="shouldShowAutoStartConversationModal" />
    <div v-if="isLoading">
      <a-spin size="small" />
    </div>
    <div v-if="!isLoading && presence">
      <div class="flex-wrapper">
        <div class="left">
          <presence-thumbnail :owner-id="member.groupId" :presence-id="presence.id"></presence-thumbnail>
        </div>
        <div class="right">
          <div @click.prevent.stop="startConversationIfNotOk">{{ presence.displayName }} <br v-if="reason" /><span class="reason-inline" v-if="reason">(Reason: {{
            reason }})</span>
            <!-- <div v-if="notOk" class="not-ok-button">
                <a-button @click.prevent.stop="startConversation">Start Conversation</a-button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import presences from "../../../../api/presences";
import PresenceThumbnail from "../../../../components/Presences/PresenceThumbnail.vue";
import AutoStartConversationModal from "./PresenceRow/AutoStartConversationModal.vue";

export default {
  components: { PresenceThumbnail, AutoStartConversationModal },
  props: ["member", "tenantId", "notOk", "orgId"],
  data() {
    return {
      isLoading: false,
      presence: null,

      shouldShowAutoStartConversationModal: false
    };
  },
  created() {
    this.loadPresence();
  },
  computed: {
    reason() {
      if (
        this.member
        && this.member.content
        && this.member.content['$v']
        && this.member.content['$v']['reason']
        && this.member.content['$v']['reason'].length
      ) {
        return this.member.content['$v']['reason'];
      } else {
        return null;
      }
    }
  },
  methods: {
    loadPresence() {
      let vm = this;
      vm.isLoading = true;
      presences
        .getPresence(this.tenantId, this.member.presenceId)
        .then((r) => {
          vm.isLoading = false;
          vm.presence = r.data;
        })
        .catch((e) => {
          console.log(e);
          // vm.$message.error("Error loading presence");
        });
    },

    startConversationIfNotOk() {
      if (this.notOk && this.member.presenceId) {
        this.startConversation();
      }
    },

    startConversation() {
      this.shouldShowAutoStartConversationModal = true;
    },

    closeConversationModal() {
      this.shouldShowAutoStartConversationModal = false;
    }
  },
};
</script>

<style scoped lang="scss">
.flex-wrapper {
  display: flex;

  .left {
    flex-shrink: 1;
    padding-right: 15px;
  }

  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
}

.reason-inline {
  // margin-left: 10px;
  // color: rgb(81, 85, 234);
}

.not-ok-button {
  margin-top: 10px;
}
</style>