import timelines from "../api/timelines";
export default {
    methods: {
        updateServerLastReadPointer(tenantId, presenceId, groupId, instant = null) {
            let lastReadInstant;
            if (instant) {
                lastReadInstant = instant;
            } else {
                lastReadInstant = window.moment().format('x') * 10000
            }
            if (!presenceId) {
                return false;
            }
            timelines.updateLastReadPointer(
                tenantId, presenceId, groupId, lastReadInstant
            );
        }
    }
}