<template>
  <div class="announcement-choice-button">
    <view-guidance-message-modal
      v-if="announcementGuidancePackId"
      :visible="showGuidanceModal"
      :my-presence-id="myPresenceId"
      :guidance-pack-id="announcementGuidancePackId"
      :content-id="announcementContentId"
      @close-modal="closeGuidanceModal"
    ></view-guidance-message-modal>
    <a-button
      :loading="isSaving"
      @click.prevent="setAnnouncementResponse"
      size="large"
      :style="buttonStyle"
      class="btn-rounded"
      :disabled="isSelected && !this.announcementGuidancePackId"
    >
      {{ choice.name }}
    </a-button>
  </div>
</template>

<script>
import announcements from "../../../api/announcements";
import ViewGuidanceMessageModal from "../../../components/ViewGuidanceMessageModal.vue";
// import { mapGetters } from "vuex";
export default {
  props: [
    "choice",
    "isSelected",
    "announcementGuidancePackId",
    "announcementContentId",

    "tenantId",
    "announcement",
    "myPresenceId",
  ],
  components: { ViewGuidanceMessageModal },
  data() {
    return {
      isSaving: false,
      showGuidanceModal: false,
    };
  },
  computed: {
    // ...mapGetters("announcementViewer", {
    //   tenantId: "tenantId",
    //   announcement: "announcement",
    //   myPresenceId: "myPresenceId",
    // }),

    buttonStyle() {
      if (!this.isSelected || this.announcementGuidancePackId) {
        return {
          backgroundColor: this.choice.backgroundColour,
          color: this.choice.foregroundColour,
        };
      } else {
        return {
          borderColor: this.choice.backgroundColour,
          color: this.choice.backgroundColour,
          opacity: 0.4,
        };
      }
    },
  },
  methods: {
    openGuidanceModal() {
      this.showGuidanceModal = true;
    },

    closeGuidanceModal() {
      this.showGuidanceModal = false;
    },

    setAnnouncementResponse() {
      if (this.announcementGuidancePackId) {
        this.openGuidanceModal();
      }

      if (this.isSelected) {
        return false;
      }

      let vm = this;
      vm.isSaving = true;
      announcements
        .setAnnouncementResponse(
          this.tenantId,
          this.announcement.id,
          this.choice.id,
          this.myPresenceId
        )
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Response set successfully");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error setting announcement response");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.announcement-choice-button {
  display: inline;
  margin-right: 10px;
}
</style>